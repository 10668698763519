<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="优惠码代码">
            <el-input class="mr-10" placeholder="请输入优惠码代码" v-model="state.queryForm.code"></el-input>
          </el-form-item>
          <el-form-item label="使用者手机号">
            <el-input class="mr-10" placeholder="请输入使用者手机号" v-model="state.queryForm.mobile"></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-select v-model="state.queryForm.enableStatus" class="m-2" @change="handleChangeSelect" placeholder="请选择票种类型">
              <el-option v-for="item in state.typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
            <el-button icon="Download" type="primary" @click="handleDownLoad">
              下载报表
            </el-button>
            <el-button icon="CirclePlus" type="primary" @click="handleAddCouponCode('add')">
              添加优惠券
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="state.list">
      <el-table-column label="优惠码代码" prop="code" show-overflow-tooltip align="center">
        <template #default="{ row }">
          {{ row.code || '暂无' }}
        </template>
      </el-table-column>
      <!--  票类型 展会EXHIBITION 论坛FORUM -->
      <el-table-column label="优惠码固定价" prop="discountPrice" show-overflow-tooltip align="center">
        <template #default="{ row }">
          {{ row.discountPrice }}
        </template>
      </el-table-column>
      <el-table-column label="生成时间" prop="createTime" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.createTime ? filterTime(row.createTime) : '暂无' }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="使用者姓名" prop="name" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.name || '暂无' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="mobile" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.mobile || '暂无' }}</div>
        </template>
      </el-table-column> -->
      <el-table-column label="使用次数总量" show-overflow-tooltip align="center">
        <template #default="{ row }">
          {{row.defineUseNum + (row.useNum ? row.useNum : 0)}}
        </template>
      </el-table-column>
      <el-table-column label="剩余可使用次数" prop="defineUseNum" show-overflow-tooltip align="center" />
      <el-table-column label="已使用次数" prop="useNum" show-overflow-tooltip align="center">
        <template #default="{ row }">
          {{row.useNum ? row.useNum : 0}}
        </template>
      </el-table-column>

      <!-- <el-table-column label="使用时间" prop="useTime" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.useTime ? filterTime(row.useTime) : '暂无' }}</div>
        </template>
      </el-table-column> -->
      <!-- 	状态 WAIT_USED 待使用 USED 已使用-->
      <el-table-column label="状态" prop="status" align="center">
        <template #default="{ row }">
          <el-tag :type="row.enableStatus == true ? 'success' : 'info'">
            {{ filterSelectStatus[row.enableStatus] }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" show-overflow-tooltip width="300" align="center">
        <template #default="{ row }">
          <el-button type="primary" @click="handleMutateStatus(row)">
            更改状态
          </el-button>
          <el-button type="primary" @click="handleAddCouponCode(`edit`, row.defineUseNum, row.discountPrice, row.id, row.defineUseNum, row.useNum)">
            修改次数
          </el-button>
          <el-button type="primary" @click="handleSeeDetail(row)">
            使用明细
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background v-if="state.total > 10" :current-page="state.queryForm.pageNum" :layout="state.layout" :page-size="state.queryForm.pageSize" :total="state.total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit-coupon-code :title='state.editTitle' ref="refCouponCodeDialog" @noticeRefresh="getData" />
    <change-coupon-status ref="refCouponStatusDialog" @noticeRefresh="getData" />

    <coupon-use ref="couponUseRef"></coupon-use>
  </div>
</template>
<script>
import couponUse from './components/couponUse.vue'
export default { name: 'ticketsManage', components: { couponUse } }
</script>
<script setup>
import { getCurrentInstance, reactive, ref, onActivated } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Search, CirclePlus, Download } from '@element-plus/icons'
import {
  reqDiscountList,
  reqDownloadDiscountCode,
} from '@/api/exhibitionManage'
import { useComponent } from './components/index'
import { debounce } from '@/utils/debounce'
import { parseTime } from '@/utils/index'
const router = useRouter() // 获取路由实例
const route = useRoute() // 获取路由对象
const loading = ref(false) // 列表动画加载
// 引入组件
const { editCouponCode, changeCouponStatus } = useComponent()

const { proxy } = getCurrentInstance()
const filterSelectStatus = {
  true: '进行中',
  false: '已停用',
}
// 添加优惠卷的ref
const refCouponCodeDialog = ref(null)
// 更改状态的ref
const refCouponStatusDialog = ref(null)

const couponUseRef = ref(null)
const state = reactive({
  queryForm: {
    code: '',
    createTime: '',
    discountPrice: 0,
    mobile: '',
    name: '',
    pageNum: 1,
    pageSize: 10,
    ticketCode: '',
    useTime: '',
  },
  total: 0,
  list: [],
  layout: 'prev, pager, next',
  editRef: null,
  editTitle: '添加优惠券',
  typeOptions: [
    {
      label: '全部',
      value: '',
    },
    {
      label: '已停用',
      value: 0,
    },
    {
      label: '进行中',
      value: 1,
    },
  ],
})

// 初始化数据
const getData = async () => {
  loading.value = true
  const { data, total } = await reqDiscountList(state.queryForm)
  state.list = data.data
  state.total = data.total
  setTimeout(() => {
    loading.value = false
  }, 500)
}
// 筛选时间
const filterTime = (val) => {
  return parseTime(val)
}
// 下载报表
const handleDownLoad = debounce(async () => {
  try {
    let res = await reqDownloadDiscountCode(state.queryForm.ticketCode)
    const data = res
    // 以excel文件为例
    const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = `下载报表_门票优惠码_${new Date().getTime()}`
    a.click()
    URL.revokeObjectURL(a.href)
    a.remove()
    setTimeout(() => {
      proxy.$baseMessage(`下载成功`, 'success', 'vab-hey-message-success')
    }, 300)
  } catch (error) {
    proxy.$baseMessage(`下载失败`, 'error', 'vab-hey-message-error')
  }
})
// 添加优惠劵
const handleAddCouponCode = (
  type = 'add',
  count = 0,
  discountPrice,
  id,
  defineUseNum,
  useNum
) => {
  console.log('添加优惠劵', type)
  if (type == 'add') {
    state.editTitle = '添加优惠券'
  } else {
    state.editTitle = '修改次数'
  }
  refCouponCodeDialog.value.handleOpen({
    ticketCode: state.queryForm.ticketCode,
    type,
    count,
    discountPrice,
    id,
    defineUseNum,
    useNum,
  })
}
// 下拉事件
const handleChangeSelect = (e) => {
  // state.queryForm.status = e
}
// 查询
const handleQueryData = debounce(() => {
  state.queryForm.pageNum = 1
  getData()
})
// 条数
const handleSizeChange = debounce((pageSize) => {
  state.queryForm.pageSize = pageSize
  getData()
})
// 页码
const handleCurrentChange = debounce((pageNum) => {
  state.queryForm.pageNum = pageNum
  getData()
})
// 更改状态
const handleMutateStatus = debounce((row, type) => {
  console.log('更改状态', row)
  refCouponStatusDialog.value.handleOpen({
    id: row.id,
    status: row.enableStatus,
  })
})

const handleSeeDetail = (row) => {
  couponUseRef.value.handleOpen({
    code: row.code,
  })
}
// 激活组件
onActivated(() => {
  state.queryForm.ticketCode = route.query?.ticketCode || ''
  state.queryForm.ticketCode && getData()
})
</script>
